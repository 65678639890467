import styled from "@emotion/styled";
import colors from "/styles/colors";
import { DARK_WORK_OS_IRIS_COLOR_NAME, BRAND_SERVICE_PRIMARY_COLOR_NAME, LIGHT_BACKGROUND_PINK } from "styles/color-consts";
export const StyledFooterCategoryComponent = styled.div`
  .footer-category-image-and-title-warpper {
    .monday-logo-wrapper {
      margin-bottom: 14px;

      .footer-monday-logo-image {
        height: 32px;
      }
    }

    .title {
      margin-top: 10px;
      margin-bottom: 24px;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }

  .link {
    line-height: 22px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:hover {
      color: ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
    }

    a {
      text-decoration: none;
      color: inherit;
      display: flex;
      column-gap: 8px;
      align-items: center;

      .picture-component {
        display: flex;

        img {
          height: 18px;
        }
      }
    }

    .beta-tag {
      font-size: 0.6875rem;
      font-weight: 400;
      color: ${colors[BRAND_SERVICE_PRIMARY_COLOR_NAME]};
      background-color: ${colors[LIGHT_BACKGROUND_PINK]};
      border-radius: 12px;
      padding: 1px 5px;
      line-height: normal;
      border-radius: 32px;
    }
  }
`;
