import styled from "@emotion/styled";
import colors from "/styles/colors";
import { DARK_WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
export const StyledSinglePaletteIconComponent = styled.div`
  svg {
    height: 24px;
    width: auto;
  }

  &:hover {
    svg {
      path {
        fill: ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
      }
    }
  }
`;
