import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import colors from "styles/colors";
export const StyledAllRightsReservedComponent = styled.div`
  .details {
    font-size: 0.8125rem;
    line-height: 2;
    height: 24px;
    color: ${colors["text-gray"]};
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    display: flex;
    margin-left: 24px;

    .icons-wrapper {
      margin-left: 48px;
    }
  }
`;
