import styled from "@emotion/styled";
import colors from "styles/colors.js";
import { IRIS_COLOR_NAME, BRAND_SERVICE_PRIMARY_COLOR_NAME, LIGHT_BACKGROUND_PINK } from "styles/color-consts";
export const StyledShortFooterLink = styled.a`
  text-decoration: none;
  cursor: default;
  width: fit-content;
  color: ${colors.black};
  line-height: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: 100ms color ease;

  .link-icon {
    height: 12px;
    width: 12px;
  }

  &.main-title {
    font-weight: 600;
    font-size: 0.875rem;

    &.clickable {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &.secondary-title {
    font-weight: 600;
    color: ${colors.asphalt};
    font-size: 0.75rem;
  }

  &.link {
    font-weight: 400;
    font-size: 0.75rem;
    cursor: pointer;

    &:hover {
      color: ${colors[IRIS_COLOR_NAME]};
    }
  }

  .beta-tag {
    font-size: 0.6875rem;
    font-weight: 400;
    color: ${colors[BRAND_SERVICE_PRIMARY_COLOR_NAME]};
    background-color: ${colors[LIGHT_BACKGROUND_PINK]};
    border-radius: 12px;
    padding: 1px 5px;
    line-height: normal;
    border-radius: 32px;
  }
`;
