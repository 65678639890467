import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledSinglePaletteIconComponent = styled.div`
  display: flex;

  .icons-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  .single-app-icon-wrapper {
    margin-right: 8px;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    margin: 0 auto;
    padding: 0;
    flex-wrap: wrap;
    margin-top: 16px;
    .icons-wrapper:first-child {
      margin-right: auto;
    }

    .icons-wrapper {
      width: 200px;
      margin: 0 auto;
      margin-bottom: 8px;
    }
  }
`;
