import { MOBILE_QUERY_MAX_WIDTH, SMALL_MOBILE_QUERY_MAX_WIDTH, MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH } from "constants/vp-sizes";
import colors from "/styles/colors";
import { WHITE_COLOR_NAME } from "styles/color-consts";
import styled from "@emotion/styled";
export const StyledDesktopFooterContainer = styled.div`
  display: block;

  @media (max-width: 1199px) {
    display: none;
  }
`;
export const StyledMobileFooterContainer = styled.div`
  display: none;

  @media (max-width: 1199px) {
    display: block;
  }
`;
export const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  color: ${colors["text-gray"]};
  background-color: ${colors[WHITE_COLOR_NAME]};

  .footer-content-container {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
    width: 100%;
    padding: 80px 32px 0 32px;
    font-size: 0.8125rem;
    display: flex;
    justify-content: center;
    position: relative;

    .footer-content {
      display: flex;
      flex-direction: column;
      width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;

      .all-right-reserved-container {
        flex-grow: 1;
        margin-right: 120px;
      }

      .categories-container {
        flex-grow: 1;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;

        .categories-colmmn-wrapper {
          display: flex;
          flex-direction: column;
          gap: 38px;
        }

        .category-container {
          flex: 1 0 auto;
          margin-bottom: 48px;
          display: flex;
          flex-direction: column;

          &:last-child {
            flex-grow: 0;
          }

          &:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
    }
  }

  &:before {
    display: block;
    content: "";
    height: 1px;
    width: 100%;
    background-color: ${colors.gainsboro};
    position: relative;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .footer-content-container {
      padding: 40px 40px 0 40px;

      .footer-content {
        flex-direction: column;
        padding-left: 24px;

        .all-right-reserved-container {
          width: 100%;
        }

        .categories-container {
          .category-container {
            width: 130px;
            align-items: center;
            margin-right: 12px;

            .footer-category-component {
              width: 130px;
            }
          }

          .security-logos-container {
            img {
              width: 34px !important;
              height: 34px !important;
            }
          }
        }
      }
    }
  }

  &.slim-footer {
    .footer-content-container {
      padding-top: 48px;

      .footer-content {
        .categories-container {
          .category-container {
            &:not(:first-child) {
              margin-top: 8px;
            }

            &:last-child {
              flex-grow: 3;
            }
          }
        }
      }
    }
  }

  @media (max-width: ${SMALL_MOBILE_QUERY_MAX_WIDTH}) {
    .footer-content-container {
      padding: 40px 16px 0 16px;
    }
  }

  @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}) {
    .footer-content-container {
      .footer-content {
        padding: 0;
      }
    }
  }
`;
