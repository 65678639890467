import styled from "@emotion/styled";
import colors from "styles/colors.js";
import { MOBILE_QUERY_MAX_WIDTH, MOBILE_QUERY_MIN_WIDTH } from "constants/vp-sizes";
export const StyledShortFooter = styled.footer`
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 80px;
  justify-content: center;

  .short-footer-header {
    width: 100%;
    justify-content: space-between;
    display: flex;
    margin-bottom: 48px;
    flex-direction: row;

    .language-picker-wrapper-component {
      width: 120px;

      .language-picker-component {
        .language-picker-dialog-component-wrapper {
          margin: 0;
        }
      }
    }
  }

  .short-footer-links {
    width: 100%;
    column-count: 4;
    flex-wrap: wrap;

    .link-wrapper {
      flex-basis: 100%;
      break-inside: avoid;
      margin: 0 0 20px;

      &.main:not(:first-child) {
        margin-top: 28px;
      }

      &.main:nth-child(15n),
      &.main:nth-child(24n),
      &.main:nth-child(36n) {
        break-before: column;
        margin-top: 0;
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    align-items: center;
    gap: 12px;

    .terms {
      display: flex;
      gap: 12px;

      .accessibility-statement-link-wrapper {
        display: flex;
        align-items: center;
      }
    }

    .footer-link {
      display: flex;
      align-items: center;
      text-decoration: underline;

      a {
        transition: 150ms color ease;
        color: ${colors["text-gray"]};
        font-size: 0.75rem;
      }
    }
  }

  .monday-logo-wrapper {
    .footer-monday-logo-image {
      height: 32px;
    }
  }

  .contact-info-wrapper {
    font-size: 0.8125rem;
    text-align: center;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    align-items: initial;
    padding: 32px;

    .short-footer-links {
      column-count: 2;
      flex-wrap: wrap;

      .link-wrapper {
        &.main:nth-child(15n),
        &.main:nth-child(36n) {
          break-before: auto;
          margin-top: 28px;
        }
      }
    }

    .bottom {
      align-items: initial;

      .contact-info-wrapper {
        text-align: left;
        width: 100%;
        line-height: 24px;

        .address-info {
          margin-top: 0;
        }
      }

      .social-proof-wrapper {
        width: 100%;

        .palette-icons-container {
          margin-top: 0;
        }

        .icons-wrapper {
          margin: 0 !important;
        }
      }
    }

    @media (max-width: ${MOBILE_QUERY_MIN_WIDTH}) {
      .terms {
        flex-wrap: wrap;

        .accessibility-statement-link-wrapper {
          flex-basis: 100%;
        }
      }
    }

    @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) and (min-width: ${MOBILE_QUERY_MIN_WIDTH}) {
      align-items: center;

      .short-footer-links {
        column-count: 3;
        flex-wrap: wrap;

        .link-wrapper {
          &.main:nth-child(36n) {
            break-before: column;
            margin-top: 0;
          }
        }
      }

      .bottom {
        width: 100%;
        align-items: center;
        justify-content: center;

        .contact-info-wrapper {
          text-align: center;
        }

        .social-proof-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;

          .icons-wrapper {
            width: fit-content;
          }
        }
      }
    }
  }
`;
