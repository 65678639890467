import styled from "@emotion/styled";
import colors from "styles/colors.js";
export const StyledFooterBottomBarLink = styled.a`
  font-size: 0.8125rem;
  color: ${colors["text-gray"]};
  transition: 100ms color ease;
  text-decoration: underline;

  &:hover {
    color: ${colors.iris};
  }
`;
