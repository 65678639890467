import styled from "@emotion/styled";
export const StyledSecurityLogosComponent = styled.div`
  display: flex;

  .iso-logo-img-container,
  .gdpr-logo-img-container,
  .hipaa-logo-img-container,
  .soc-logo-img-container {
    margin-right: 8px;
    cursor: pointer;

    img {
      height: 32px;
    }

    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }
`;
