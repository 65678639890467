import styled from "@emotion/styled";
import colors from "/styles/colors";
import { BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MOBILE_QUERY_MAX_WIDTH, TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import headerConstants from "segments/desktop/header/layouts/components/basic-header/constants";
const TOP_ROW_HEIGHT = 40;
const BOTTOM_ROW_HEIGHT = 24;
const FOOTER_PADDING = headerConstants.HEADER_CONTENT_HORIZONTAL_PADDING * 2;
const FOOTER_PADDING_STRING = `${FOOTER_PADDING}px`;
const FOOTER_WIDTH = `calc(100% - ${FOOTER_PADDING_STRING})`;
export const StyledFooterBottomBarComponent = styled.div`
  position: relative;
  font-size: 0.8125rem;
  color: ${colors["brand-gray"]};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &:before {
    display: block;
    content: "";
    height: 1px;
    width: ${FOOTER_WIDTH};
    background-color: ${colors.gainsboro};
    position: relative;
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH - FOOTER_PADDING}px;
  }

  .footer-contact-sales-section {
    padding: 36px;
    color: ${colors[BRAND_BLACK_TEXT_COLOR]};
    text-align: center;
    .footer-contact-sales-title {
      .core-title {
        font-weight: 600;
      }
      margin-bottom: 8px;
    }
    .footer-contact-sales-subtitle {
      .paragraph-wrapper .paragraph-body {
        font-size: 1rem;
      }
    }

    .footer-contact-sales-button-wrapper {
      margin-top: 32px;
      display: flex;
      justify-content: center;

      .regular-button {
        .button-component {
          font-weight: 400;
        }
      }
    }
  }

  .footer-bottom-bar-both-sides-wrapper {
    max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 40px 32px 48px;
    align-items: center;
    height: 180px;
  }

  .footer-bottom-bar-left-side {
    display: flex;
    flex-direction: row;
    gap: 32px;
    height: 100%;

    .language-picker-and-security-logos-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      .language-picker-wrapper {
        height: 40px;
        display: flex;
        align-items: center;
      }
    }

    .social-statement-and-links-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      .palette-icons-wrapper {
        height: 40px;
        display: flex;
      }

      .statement-and-links-wrapper {
        height: ${BOTTOM_ROW_HEIGHT}px;
        display: flex;
        align-items: center;
        padding-bottom: 6px;
        gap: 24px;

        .links-container {
          display: flex;
          flex-wrap: wrap;
          row-gap: 4px;
          max-width: 90%;
          
          .short-footer-link-component-wrapper {
            display: flex;
            align-items: center;

            &:not(:last-of-type):after {
              content: " | ";
              margin: 0 8px;
            }
          }
        }

        .all-rights-reserved-component {
          padding-bottom: 2px;
          white-space: nowrap;
          padding-right: 20px;
        }
      }
    }
  }

  .footer-bottom-bar-right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .app-store-icons {
      display: flex;
      gap: 16px;
      height: ${TOP_ROW_HEIGHT}px;
    }

    .accessibility-statement-link-wrapper {
      height: ${BOTTOM_ROW_HEIGHT}px;
      display: flex;
      align-items: center;
      padding-bottom: 6px;
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) and (min-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .items-container {
      padding: 0 54px;
    }
  }
`;
